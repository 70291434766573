<script>
import RegionBase from './RegionBase'
import { europeManifest } from '../ea-vgas-giveaway-manifest'

export default {
  extends: RegionBase,
  data() {
    return {
      regionId: 'europe',
      activeIntro: 'Giveaways for Europe',
      gameList: europeManifest
    }
  },
  metaInfo() {
    return {
      title: 'The Game Awards Giveaways - Europe',
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
</script>
<style lang="scss" src="@/assets/themes/ea-vgas/scss/main.scss" />
